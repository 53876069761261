import { useRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { newOption, newNone, newSome, Option } from "../../../util/result";
import * as drawing from "../../../util/drawing";
import {
	IrisRadii,
	EyesSelectedHandle as SelectedHandle,
	EyesHandle as Handle,
	Position,
	ImageUtils,
} from "../../../util/drawing";
import { useBrandingStore } from "../../../store";

export type EyesCanvasProps = {
	width: number;
	height: number;

	imageObject: HTMLImageElement;
	leftHandleObject: HTMLImageElement;
	rightHandleObject: HTMLImageElement;

	irisRadii: IrisRadii;
	irisPosition: { left: Position; right: Position };
	onIrisPositionChange: (
		p:
			| { left: Position; right: Position }
			| ((p: { left: Position; right: Position }) => {
					left: Position;
					right: Position;
			  }),
	) => void;
	onIrisPositionFinialized: (p: { left: Position; right: Position }) => void;
	imageUtils: ImageUtils;

	leftHand: boolean;
	alwaysUseLoupe: boolean;
};

type CanvasState = {
	selectedHandle: Option<SelectedHandle>;
	lastRapidPointerDownEvent: Option<number>;
};

const rapidTapTimeMS = 200;

const EyesCanvas = ({
	width,
	height,
	irisRadii,
	irisPosition,
	imageUtils,
	imageObject,
	leftHandleObject,
	rightHandleObject,
	leftHand,
	alwaysUseLoupe,
	onIrisPositionChange,
	onIrisPositionFinialized,
}: EyesCanvasProps) => {
	const { t } = useTranslation();
	const canvasRefNullable = useRef<HTMLCanvasElement>(null);
	const [error, setError] = useState<Option<string>>(newNone());
	const { branding } = useBrandingStore();

	const [canvasState, setCanvasState] = useState<CanvasState>({
		selectedHandle: newNone(),
		lastRapidPointerDownEvent: newNone(),
	});

	const handleColor = useMemo<string>(
		() =>
			getComputedStyle(document.body).getPropertyValue(
				branding === "hoya"
					? "--system-highlight--100"
					: "--pure-black--100",
			),
		[],
	);

	const onPointerDownEventHandler =
		(canvasRef: HTMLCanvasElement) => (event: PointerEvent) => {
			const [lBox, rBox] = [
				{
					ix: irisPosition.left.x,
					iy: irisPosition.left.y,
					ir: irisRadii.left,
					hl: leftHandleObject,
					hr: rightHandleObject,
				},
				{
					ix: irisPosition.right.x,
					iy: irisPosition.right.y,
					ir: irisRadii.right,
					hl: leftHandleObject,
					hr: rightHandleObject,
				},
			].map(({ ix, iy, ir, hl, hr }) => {
				const iris = imageUtils.fromImageToCanvas(ix, iy);
				const irisDiam = imageUtils.scaleFromImageToCanvas(ir * 2);
				const handle = leftHand ? hl : hr;
				return drawing.calculateHandleBox(
					iris.x,
					iris.y,
					imageUtils.scaleFromImageToCanvas(ir),
					irisDiam,
					handle.height * (irisDiam / handle.width),
					!leftHand,
				);
			});

			[
				{ box: lBox, code: "l" as Handle },
				{ box: rBox, code: "r" as Handle },
			].forEach(({ box, code }) => {
				if (
					drawing.isInsideBox(
						{ x: event.offsetX, y: event.offsetY },
						box,
					)
				) {
					setCanvasState({
						selectedHandle: newSome({
							handle: code,
							previousPosition: {
								x: event.offsetX,
								y: event.offsetY,
							},
							doubleTap: canvasState.lastRapidPointerDownEvent
								.map((t) => Date.now() - t < rapidTapTimeMS)
								.unwrapOrDefault(false),
						}),
						lastRapidPointerDownEvent: newSome(Date.now()),
					});
				}
			});
		};

	const onPointerMoveEventHandler =
		(canvasRef: HTMLCanvasElement) => (event: PointerEvent) => {
			if (canvasState.selectedHandle.kind === "some") {
				const selectedHandle = canvasState.selectedHandle.data;

				const movement = {
					x: imageUtils.scaleFromCanvasToImage(
						event.offsetX - selectedHandle.previousPosition.x,
					),
					y: imageUtils.scaleFromCanvasToImage(
						event.offsetY - selectedHandle.previousPosition.y,
					),
				};
				if (selectedHandle.handle === "l") {
					onIrisPositionChange((irisPos) => ({
						...irisPos,
						left: {
							...irisPos.left,
							x: irisPos.left.x + movement.x,
							y: irisPos.left.y + movement.y,
						},
					}));
				} else {
					onIrisPositionChange((irisPos) => ({
						...irisPos,
						right: {
							...irisPos.right,
							x: irisPos.right.x + movement.x,
							y: irisPos.right.y + movement.y,
						},
					}));
				}
				setCanvasState({
					selectedHandle: newSome({
						...selectedHandle,
						previousPosition: {
							x: event.offsetX,
							y: event.offsetY,
						},
					}),
					lastRapidPointerDownEvent: newNone(),
				});
				event.preventDefault();
				event.stopPropagation();
			}
		};

	const onPointerUpEventHandler =
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		(_: HTMLCanvasElement) => (_: PointerEvent) => {
			setCanvasState((state) => ({
				...state,
				selectedHandle: newNone(),
			}));
			onIrisPositionFinialized(irisPosition);
		};

	useEffect(() => {
		const canvasRefOpt = newOption(canvasRefNullable.current);
		const canvasRef = canvasRefOpt.expect(
			"This is called after componentDidMount. CanvasRef is now not null.",
		);

		const downHandler = onPointerDownEventHandler(canvasRef);
		const moveHandler = onPointerMoveEventHandler(canvasRef);
		const upHandler = onPointerUpEventHandler(canvasRef);

		const scrollHandler = (event: Event) => {
			if (canvasState.selectedHandle.kind === "some") {
				event.preventDefault();
			}
		};

		canvasRef.addEventListener("pointerdown", downHandler);
		canvasRef.addEventListener("pointermove", moveHandler);
		canvasRef.addEventListener("pointerup", upHandler);

		window.addEventListener("touchmove", scrollHandler, { passive: false });

		return () => {
			canvasRef.removeEventListener("pointerdown", downHandler);
			canvasRef.removeEventListener("pointermove", moveHandler);
			canvasRef.removeEventListener("pointerup", upHandler);

			window.removeEventListener("touchmove", scrollHandler);
		};
	}, [canvasRefNullable, canvasState, leftHand, irisPosition]);

	useEffect(() => {
		const canvasRefOpt = newOption(canvasRefNullable.current);
		const canvasRef = canvasRefOpt.expect(
			"This is called after componentDidMount. CanvasRef is now not null.",
		);

		const ctxOpt = newOption(canvasRef.getContext("2d"));
		if (ctxOpt.kind === "none") {
			setError(newSome(t("eyesCanvas.contextError")));
		} else {
			const ctx = ctxOpt.data;
			draw(
				ctx,
				imageUtils,
				width,
				height,
				irisPosition,
				irisRadii,
				imageObject,
				leftHandleObject,
				rightHandleObject,
				handleColor,
				leftHand,
				canvasState.selectedHandle,
				alwaysUseLoupe,
			);
		}
	});

	return error.kind === "none" ? (
		<canvas
			ref={canvasRefNullable}
			width={width}
			height={height}
			className="select-none"
		></canvas>
	) : (
		<p>{error.data}</p>
	);
};

const draw = (
	ctx: CanvasRenderingContext2D,
	imageUtils: ImageUtils,
	width: number,
	height: number,
	irisPosition: { left: Position; right: Position },
	irisRadii: IrisRadii,
	imageObject: HTMLImageElement,
	leftHandleObject: HTMLImageElement,
	rightHandleObject: HTMLImageElement,
	handleColor: string,
	leftHand: boolean,
	selectedHandle: Option<SelectedHandle>,
	alwaysUseLoupe: boolean,
) => {
	ctx.clearRect(0, 0, width, height);

	if (imageUtils.viewBoxImage.y >= 0) {
		ctx.drawImage(
			imageObject,
			imageUtils.viewBoxImage.x,
			imageUtils.viewBoxImage.y,
			imageUtils.viewBoxImage.width,
			imageUtils.viewBoxImage.height,
			0,
			0,
			width,
			height,
		);
	} else {
		ctx.drawImage(
			imageObject,
			imageUtils.viewBoxImage.x,
			0,
			imageUtils.viewBoxImage.width,
			imageUtils.viewBoxImage.height,
			0,
			imageUtils.scaleFromImageToCanvas(-imageUtils.viewBoxImage.y),
			width,
			height,
		);
	}

	const left = imageUtils.fromImageToCanvas(
		irisPosition.left.x,
		irisPosition.left.y,
	);
	const right = imageUtils.fromImageToCanvas(
		irisPosition.right.x,
		irisPosition.right.y,
	);
	const leftRadius = imageUtils.scaleFromImageToCanvas(irisRadii.left);

	const rightRadius = imageUtils.scaleFromImageToCanvas(irisRadii.right);

	drawing.drawHandleWithOrWithoutLoupe(
		ctx,
		leftHandleObject,
		rightHandleObject,
		imageObject,
		right,
		rightRadius,
		{ x: irisPosition.right.x, y: irisPosition.right.y },
		irisRadii.right,
		0,
		handleColor,
		1,
		leftHand,
		selectedHandle
			.map((h) => h.handle === "r" && (alwaysUseLoupe || h.doubleTap))
			.unwrapOrDefault(false),
	);

	drawing.drawHandleWithOrWithoutLoupe(
		ctx,
		leftHandleObject,
		rightHandleObject,
		imageObject,
		left,
		leftRadius,
		{ x: irisPosition.left.x, y: irisPosition.left.y },
		irisRadii.left,
		0,
		handleColor,
		1,
		leftHand,
		selectedHandle
			.map((h) => h.handle === "l" && (alwaysUseLoupe || h.doubleTap))
			.unwrapOrDefault(false),
	);
};

export default EyesCanvas;
