import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	OutlineButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import {
	SettingsConnectivityStatus,
	SettingsFragment,
} from "../../components/fragments";
import { Switch } from "../../components/input";
import { ModalBase, ModalUpdate } from "../../components/modals";
import { InfoPanel } from "../../components/panels";
import { ButtonText, HeadingText } from "../../components/typography";
import {
	about,
	getServerStatus,
	getSettings,
	getUpdateDescription,
	restart,
	setUpdateEnabled,
} from "../../util/api/api-store";
import { getAccessIdAsPromise, useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";
import { OSArrowLeft } from "../../components/icons";

const Update = () => {
	const { t, i18n } = useTranslation();
	const { branding } = useBrandingStore();
	const [modalStartOpen, setModalStartOpen] = useState<boolean>(false);
	const [modalWorkingOpen, setModalWorkingOpen] = useState<boolean>(false);
	const [modalFailedOpen, setModalFailedOpen] = useState<boolean>(false);
	const [showDetails, setShowDetails] = useState<boolean>(false);

	const getCurrentLng = () =>
		i18n.language || window.localStorage.i18nextLng || "";

	const closeStartModal = () => {
		setModalStartOpen(false);
		setIsSubmited(false);
		setShowDetails(false);
	};

	const closeWorkingModal = () => {
		setModalWorkingOpen(false);
	};

	const closeFailedModal = () => {
		setModalFailedOpen(false);
	};

	const startUpdate = () => {
		closeStartModal();
		setIsSubmited(true);
		setModalWorkingOpen(true);

		getAccessIdAsPromise().then((access_id) => {
			restart.setRequest({
				access_id: access_id,
				action: "update",
			});
			restart
				.fetchData()
				.then((result) => {
					console.log(result);
					closeWorkingModal();

					getSettings.setRequest(null);
					getSettings.invalidate();
					getSettings.fetchData();

					getServerStatus.setRequest(null);
					getServerStatus.invalidate();
					getServerStatus.fetchData();

					about.setRequest(null);
					about.invalidate();
					about.fetchData();
				})
				.catch((error) => {
					console.error(error);
					closeWorkingModal();
					setModalFailedOpen(true);
				})
				.finally(() => {
					setIsSubmited(false);
				});
		});
		// setTimeout(() => {
		// 	setModalWorkingOpen(true);
		// }, 500);
		// setTimeout(() => {
		// 	closeWorkingModal();
		// }, 4000);
		// setTimeout(() => {
		// 	setModalFailedOpen(true);
		// }, 4500);
	};

	const [stuck, setStuck] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);

	const settingsResponse = getSettings.useGetResult();

	const settings = settingsResponse.unwrapOrDefault({
		admin_mode: false,
		auto_exposure: 0,
		branding: "hoya",
		brightness: 0,
		contrast: 0,
		contrast_max: 0,
		contrast_min: 0,
		contrast_step: 0,
		debug_capture_timing: false,
		direct_loupe: false,
		expose_face_form_angle: false,
		exposure_time: 0,
		exposure_time_max: 0,
		exposure_time_min: 0,
		exposure_time_step: 0,
		eye_catcher: false,
		gain_max: 0,
		gain_min: 0,
		hide_shutdown: false,
		hide_wifi_settings: false,
		history_show_preview: false,
		inactive_time: 0,
		isp_digital_gain_max: 0,
		measurement_retention_days: 3,
		measurements_permanent: false,
		modules: {
			ai: false,
			b2b_optic: false,
			b2b_optic_download: false,
		},
		onboard: false,
		order_id_fixed: false,
		order_id_required: false,
		shop_name: "",
		show_aux_points: false,
		show_coordinates: false,
		show_editor_changes: false,
		show_frame_edge_editor: false,
		show_menu_item_debug: false,
		show_reevaluate_button: false,
		starlight_available: false,
		starlight_enabled: false,
		update_enabled: false,
		// user_instructions_url: string // The response does not contain this key.
	});

	const serverStatusResponse = getServerStatus.getResult();

	const serverStatus = serverStatusResponse.unwrapOrDefault({
		show_scheduler: false,
		update_ready: false,
		updated_from: null,
		version: "",
	});

	const systemInfoResponse = about.useGetResult();

	const systemInfo = systemInfoResponse.unwrapOrDefault({
		master_system: "",
		mainboard_serial_nr: "",
		public_version: "",
		space_info: {
			available: 0,
			capacity: 0,
			free: 0,
		},
		system_nr: 0,
		user_instructions_url: "", // Could be a problem
		versions: {
			firm_ware: "",
			frame_tracer: "",
			hard_ware: "",
			inet_tools_master: "",
			inet_tools_tegra: "",
			master: "",
			nxweb: "",
			server: "",
			user_interface: "",
			web_handler: "",
		},
	});

	const getUpdateDescriptionResponse = getUpdateDescription.useGetResult();
	const { title: updateTitle, description: updateDescription } =
		getUpdateDescriptionResponse.unwrapOrDefault({
			title: "",
			description: [],
		});

	useEffect(() => {
		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] },
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}

		getSettings.setRequest(null);
		getSettings.fetchData();

		about.setRequest(null);
		getServerStatus.setRequest(null);

		Promise.all([getServerStatus.fetchData(), about.fetchData()]).then(
			([getServerStatusResponse, aboutResponse]) => {
				console.log(getServerStatusResponse, aboutResponse);
				if (getServerStatusResponse.update_ready) {
					const data = {
						lang: getCurrentLng().replace("-", "_"),
						ver: getServerStatusResponse.version,
						ver_from: getServerStatusResponse.updated_from
							? getServerStatusResponse.updated_from
							: aboutResponse.versions.server,
						branding: branding,
					};
					getUpdateDescription.setRequest(data);
					getUpdateDescription.setUrlparams({
						code: "tJ2AtyTyNb7scM8iaTnY2Q4Xq%2FH5%2FZroC9zRn2YjQl8bNTxZa0K%2FSg%3D%3D",
					});
					getUpdateDescription.fetchData();
				}
			},
		);
	}, []);

	const onSubmit = () => {
		setIsSubmited(true);
		// setTimeout(() => {
		// 	setIsSubmited(false);
		// }, 4000);
		setModalStartOpen(true);
	};

	return (
		<div>
			<HeadingText
				h={4}
				className={cn(branding !== "hoya" && "py-2 !text-[26px]")}
			>
				{t("update.pageTitle")}
			</HeadingText>
			<SettingsFragment borderBottom>
				{settings.admin_mode && (
					<InfoPanel
						type="default"
						title={t("update.noPermissionToUpdate")}
						message={
							t("update.noPermissionToUpdateDescription") ||
							undefined
						}
						className="mb-8"
					/>
				)}
				{serverStatus.update_ready ? (
					<SettingsConnectivityStatus type="warning">
						<strong>{t("update.notUpToDate")}</strong>
					</SettingsConnectivityStatus>
				) : (
					<SettingsConnectivityStatus type="success">
						<strong>{t("update.upToDate")}</strong> (
						{t("application.title_visureal")}{" "}
						{t("application.title")} {systemInfo.public_version})
					</SettingsConnectivityStatus>
				)}
			</SettingsFragment>
			{serverStatus.update_ready && (
				<SettingsFragment borderBottom>
					<div
						id="details"
						className={cn(
							"prose prose-li:marker:text-primary-dark-100",
						)}
					>
						<p>
							<strong>
								{t("update.updateAvailable", {
									name: t("application.title_visureal"),
									title: t("application.title"),
									version: serverStatus.version,
								})}
							</strong>
						</p>
						{updateDescription.length !== 0 && (
							<>
								<p>{t("update.featureList")}</p>
								<ul>
									{updateDescription.map((item, index) => (
										<li key={index}>{item}</li>
									))}
								</ul>
							</>
						)}
					</div>
				</SettingsFragment>
			)}
			<SettingsFragment title={t("update.updateSettings") || undefined}>
				<Switch
					value={settings.update_enabled}
					setValue={(v) => {
						setIsSubmited(true);
						setUpdateEnabled.setRequest({
							update_enabled: v,
						});
						setUpdateEnabled.fetchData().then(() => {
							getSettings.invalidate();
							getSettings.fetchData();
							setIsSubmited(false);
						});
					}}
					label={t("update.updateSettingsDescription")}
					info={t("update.updateSettingsInfo") || undefined}
					disabled={isSubmited || settings.admin_mode}
				/>
			</SettingsFragment>
			{serverStatus.update_ready && (
				<div
					ref={buttonRef}
					className={cn(
						"sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
					)}
				>
					<div
						className={cn(
							"grid h-[72px] items-center justify-end",
							stuck && "shadow-[0px_-1px_0px_#e6e8e9]",
						)}
					>
						<SolidButton
							color="primary"
							onClick={onSubmit}
							disabled={isSubmited || settings.admin_mode}
						>
							{t("update.updateNow")}
						</SolidButton>
					</div>
				</div>
			)}
			<ModalBase
				title={t("update.newUpdateAvailable") || undefined}
				open={modalStartOpen}
				small
				close={closeStartModal}
			>
				<div className={cn(branding !== "hoya" ? "px-12 py-8" : "p-6")}>
					<p
						className={cn(
							branding !== "hoya" ? "mb-5 text-center" : "mb-3",
						)}
					>
						{t("update.newUpdateDescription")}
					</p>
					<p
						className={cn(
							"mb-10",
							branding !== "hoya" &&
								"flex items-center justify-center gap-1",
						)}
					>
						<ButtonText
							type="bold"
							className={cn(
								"cursor-pointer text-primary-100",
								branding !== "hoya" && "",
							)}
							onClick={() => setShowDetails(!showDetails)}
						>
							{t("update.updateDetails")}
						</ButtonText>
						{branding !== "hoya" && (
							<OSArrowLeft className="-scale-100 text-primary-100" />
						)}
					</p>
					{showDetails && updateDescription.length !== 0 && (
						<div
							className={cn(
								"prose mb-10 prose-li:marker:text-primary-dark-100",
							)}
						>
							<ul>
								{updateDescription.map((item, index) => (
									<li key={index}>{item}</li>
								))}
							</ul>
						</div>
					)}
					<SolidButton
						color="primary"
						onClick={startUpdate}
						fullWidth
					>
						{t("update.updateNow")}
					</SolidButton>
					{branding !== "hoya" ? (
						<OutlineButton
							color="primary"
							className={cn("mt-4 text-primary-100")}
							onClick={closeStartModal}
							fullWidth
						>
							{t("update.dontRemind")}
						</OutlineButton>
					) : (
						<TextButton
							color="primary"
							className={cn("mt-4 text-primary-100")}
							onClick={closeStartModal}
							fullWidth
						>
							{t("update.dontRemind")}
						</TextButton>
					)}
				</div>
			</ModalBase>
			<ModalBase
				disableHeader
				open={modalWorkingOpen}
				close={closeWorkingModal}
			>
				<ModalUpdate />
			</ModalBase>
			<ModalBase
				title={t("update.installationFailed") || undefined}
				open={modalFailedOpen}
				small
				close={closeFailedModal}
			>
				<div className={cn(branding !== "hoya" ? "px-12 py-8" : "p-6")}>
					<p className={cn(branding !== "hoya" ? "mb-8" : "mb-6")}>
						{t("update.errorWhileUpdate")}
					</p>
					<p className="text-right">
						<SolidButton
							color="primary"
							fullWidth={branding !== "hoya"}
							onClick={closeFailedModal}
						>
							{t("update.tryAgain")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
		</div>
	);
};

export default Update;
