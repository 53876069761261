import J from "joi";
import { type BrandingType } from "./get-settings";

export interface IRequestData {
	show_reevaluate_button: boolean;
}

export interface IResponseData {
	branding: BrandingType;
	modules: {
		b2b_optic: boolean;
		b2b_optic_download: boolean;
	};
	show_reevaluate_button: boolean;
}

export type IResponseError = null;

const responseDataShape = J.object({
	branding: J.string().valid(
		"hoya",
		"vs",
		"ollendorf",
		"optiswiss",
		"visiono",
	),
	modules: {
		b2b_optic: J.boolean(),
		b2b_optic_download: J.boolean(),
	},
	show_reevaluate_button: J.boolean(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "oms_config";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
