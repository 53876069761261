import { Dispatch, SetStateAction } from "react";
import { ButtonText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

type TabProps = {
	state: "Option1" | "Option2";
	setState: Dispatch<SetStateAction<"Option1" | "Option2">>;
	options: {
		option1: {
			label: string;
			disabled?: boolean;
		};
		option2: {
			label: string;
			disabled?: boolean;
		};
	};
};

type TabTileProps = {
	selected: boolean;
	children: string;
	onClick?: () => void;
};

const TabTile = ({ children, selected, onClick }: TabTileProps) => {
	const { branding } = useBrandingStore();

	return (
		<button
			className={cn(
				"relative overflow-hidden",
				"h-12 py-3",
				selected ? "bg-quaternary-100" : "bg-pure-white",
				"select-none transition duration-300 ease-in-out",
				branding !== "hoya" &&
					"border first:rounded-bl-[20px] first:rounded-tl-[20px] last:rounded-br-[20px] last:rounded-tr-[20px]",
				branding !== "hoya" && selected
					? "border-primary-100 bg-primary-100"
					: "border-quaternary-80",
			)}
			onClick={onClick}
		>
			<ButtonText
				type="bold"
				className={cn(
					branding !== "optiswiss"
						? selected
							? "text-primary-dark-100"
							: "text-primary-100"
						: selected
							? "text-pure-white"
							: "text-primary-100",
				)}
			>
				{children}
			</ButtonText>
		</button>
	);
};

const TabComponent = ({ setState, state, options }: TabProps) => {
	const { option1, option2 } = options;
	const { branding } = useBrandingStore();

	return (
		<div
			className={cn(
				"grid w-full grid-cols-2",
				branding === "hoya" && "border border-quaternary-100",
			)}
		>
			<TabTile
				selected={state === "Option1"}
				onClick={() => (option1.disabled ? null : setState("Option1"))}
			>
				{option1.label}
			</TabTile>
			<TabTile
				selected={state === "Option2"}
				onClick={() => (option2.disabled ? null : setState("Option2"))}
			>
				{option2.label}
			</TabTile>
		</div>
	);
};

export default TabComponent;
